
import BasicForm from '../../Forms/BasicForm/BasicForm'
import SocialIcon from '../SocialIcon/SocialIcon'
import './Contact.css'
import { LoadScript, GoogleMap } from '@react-google-maps/api';


function Contact() {

  return (
  <section id='section-contact' className='Contact'>
    <div className="ContactMap">
        <iframe
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d81340.91883935008!2d30.333822386162435!3d50.424265570178484!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x40d4cb966af4228b%3A0x5ba8bb8be321c3b5!2zQmFsbG9vbiZGbG93ZXIgc3R1ZGlvIHwg0JTQvtGB0YLQsNCy0LrQsCDQutGD0LvRjNC-0Log0Lcg0LPQtdC70ZbRlNC8!5e0!3m2!1sru!2sua!4v1739287138160!5m2!1sru!2sua"
        // width="100%"
        // height="100%"
        style={{ border: 0 }}
        allowFullScreen=""
        loading="lazy"
        referrerPolicy="no-referrer-when-downgrade"
        ></iframe>
    </div>
    <div className='container'>
        <div className='ContactContent'>
            <h2>Завжди на звязку</h2>
            <p>
              Зв'яжіться з нами за допомогою форми зворотнього зв'язку нижче або через наші соціальні мережі. Ми швидко відповімо на ваші запитання та надамо необхідну інформацію.
            </p>
            <p>
              <a href='tel:+380631531173' className='underline'>+380631531173</a><br/>
              <a href='mailto:ballonflower2024@gmail.com' className='underline'>ballonflower2024@gmail.com</a>
            </p>
            <BasicForm />
            <SocialIcon />
        </div>
    </div>
  </section>
 
  )
}

export default Contact