
import TitleSection from '../Title/TitleSection'
import './PopularCategory.css'

import PopularArrow from '../../../Assets/Icons/popularArrow.svg'
import PopularCard1 from '../../../Assets/Img/PopularCategory/card1.webp'
import PopularCard2 from '../../../Assets/Img/PopularCategory/card2.webp'
import PopularCard3 from '../../../Assets/Img/PopularCategory/card3.webp'
import PopularCard4 from '../../../Assets/Img/PopularCategory/card4.webp'
import PopularCard5 from '../../../Assets/Img/PopularCategory/card5.webp'
import PopularCard6 from '../../../Assets/Img/PopularCategory/card6.webp'

function PopularCategory() {
  return (
  <section className='PopularCategory'>
    <div className='container'>
        <TitleSection title="Популярні категорії" />
        <div className='PopularWrapper'>
            <div className='PopularRow'>
                <div className='PopularCol'>
                    <div className='PopularCard'>
                        <img src={PopularCard1} alt='' className='PopularImg'/>
                        <div className='PopularContent'>
                            <h3 className=''>На 1-й день народження</h3>
                            <a href='/shop/Na%20pershiy%20den%20narodzhennya'>
                                <p>Детальніше</p>
                                <img src={PopularArrow} alt='' className='PopularIcon'/>
                            </a>
                        </div>
                    </div>
                    <div className='PopularCard'>
                        <img src={PopularCard2} alt='' className='PopularImg'/>
                        <div className='PopularContent'>
                            <h3 className=''>Виписка з пологового</h3>
                            <a href='/shop/Vipiska%20z%20roddomu'>
                                <p>Детальніше</p>
                                <img src={PopularArrow} alt='' className='PopularIcon'/>
                            </a>
                        </div>
                    </div>
                </div>
                <div className='PopularCol'>
                <div className='PopularCard'>
                        <img src={PopularCard3} alt='' className='PopularImg'/>
                        <div className='PopularContent'>
                            <h3 className='text-white'>На День Народження</h3>
                            <a href='/shop/Na%20pershiy%20den%20narodzhennya'>
                                <p>Детальніше</p>
                                <img src={PopularArrow} alt='' className='PopularIcon'/>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
            <div className='PopularRow'>
                <div className='PopularCol'>
                    <div className='PopularCard'>
                        <img src={PopularCard4} alt='' className='PopularImg'/>
                        <div className='PopularContent'>
                            <h3 className='text-white'>На дівич<br/> вечір</h3>
                            <a href='/shop/Divich-parubocka%20vechirka'>
                                <p>Детальніше</p>
                                <img src={PopularArrow} alt='' className='PopularIcon'/>
                            </a>
                        </div>
                    </div>
                    <div className='PopularCard'>
                        <img src={PopularCard5} alt='' className='PopularImg'/>
                        <div className='PopularContent'>
                            <h3 className=''>Мультяшні<br/> герої</h3>
                            <a href='/shop/Supergeroyi'>
                                <p>Детальніше</p>
                                <img src={PopularArrow} alt='' className='PopularIcon'/>
                            </a>
                        </div>
                    </div>
                </div>
                <div className='PopularCol'>
                <div className='PopularCard'>
                        <img src={PopularCard6} alt='' className='PopularImg'/>
                        <div className='PopularContent'>
                            <h3 className=''>Хлопчик?<br/> Дівчика?</h3>
                            <a href='/shop/Genderpati'>
                                <p>Детальніше</p>
                                <img src={PopularArrow} alt='' className='PopularIcon'/>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
  </section>
  )
}

export default PopularCategory