import './Contacts.css'
import Contact from "../../Components/All/Contact/Contact"
import ContactBg from "../../Assets/Img/Contacts/bg.webp"

function Contacts() {

  return (
  <>
    <section className='Contacts sm:bg-contacts-bg'>
      <div className='container'>
        <div className='containerWrapper'>
          <div className='content'>
            <img className=" " src={ContactBg}/>
            <h1>Balloon&Flower</h1>
            <p>
              Зв'язатися з нами - це просто! Ми завжди раді надати вам допомогу та відповісти на всі ваші запитання. Наша команда працює з понеділка по п'ятницю з 9:00 до 21:00 за київським часом. Якщо у вас виникли питання щодо наших товарів, оформлення замовлення або доставки, будь ласка, не соромтеся звертатися до нас.<br />
              <br />
              Зв'яжіться з нами за допомогою форми зворотнього зв'язку нижче, і наші спеціалісти зв'яжуться з вами найближчим часом. Якщо ваше питання термінове або ви бажаєте отримати швидку консультацію, ви також можете зателефонувати нам за номером, вказаним нижче.
            </p>
            <p>
              Розклад:<br/>
              Пн-Пт: 9:00-21:00<br/>
              Сб-Нд: вихідний
            </p>
            <div>
              <a href='https://www.instagram.com/fotozona_kiev_mj_decor_agency?igsh=MTJ0ZWUyc2JzdXVqZA==' className='button buzok'>Перейти в Інстаграм</a>
            </div>
          </div>
        </div>
      </div>
    </section>
    <Contact />
  </>
  )
}

export default Contacts