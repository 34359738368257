import './About.css'

import AboutBg1 from "../../Assets/Img/About/AboutBg1.webp"
import AboutBg2 from "../../Assets/Img/About/AboutBg2.webp"

function About() {

  return (
  <>
    <section className='About sm:bg-about-bg-1'>
      <div className='container'>
        <div className='containerWrapper'>
          <div className='content'>
            <img className=" " src={AboutBg1}/>
            <h1>Balloon&Flower</h1>
            <p>
              Наша компанія - це творча команда професіоналів, яка присвятила свою роботу створенню незабутніх моментів радості та веселощів для наших клієнтів. <br />
              <br />
              Ми спеціалізуємося на продажі надувних кульок, фотозон та гірлянд для будь-яких святкових заходів та важливих подій у вашому житті.<br />
              <br />
              З нами ви отримуєте не лише якісний продукт і надійні послуги доставки, але й особисте відношення та професійну консультацію з вибору товарів. Ми віримо, що кожна деталь має значення, тому ми докладаємо всіх зусиль, щоб забезпечити вашому святковому заходу неповторну атмосферу та яскраві враження.<br />
              <br />
              Приєднуйтесь до нас у світі веселих свят та поділіться вашими моментами радості разом з нами! Слідкуйте за нашими оновленнями та інспіраційними ідеями на Instagram.
            </p>
            <div>
              <a href='https://www.instagram.com/fotozona_kiev_mj_decor_agency?igsh=MTJ0ZWUyc2JzdXVqZA==' className='button buzok'>Перейти в Інстаграм</a>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section className='About bg-about-bg-2'>
      <div className='container'>
        <div className='containerWrapper'>
          <div className='content bg-[#ffffff] bg-opacity-60 rounded-[20px] border-[1px] border-a-active-0 ml-auto p-[20px] sm:p-[40px]'>
            <p>Наш інтернет-магазин надувних кульок є вашим надійним партнером у створенні незабутніх святкових моментів. </p>
            <div className='flex flex-col gap-[8px]'>
              <h6>Широкий асортимент: </h6>
              <p>Ми пропонуємо різноманітні надувні кульки, фотозони та гірлянди, що відповідають різним святковим заходам та подіям.</p>
            </div>
            <div className='flex flex-col gap-[8px]'>
              <h6>Якість і надійність:</h6>
              <p>Наші товари виготовлені з високоякісних матеріалів, що гарантує їх тривалу експлуатацію та стійкість до пошкоджень.</p>
            </div>
            <div className='flex flex-col gap-[8px]'>
              <h6>Швидка доставка:</h6>
              <p>Ми пропонуємо швидку та надійну доставку наших товарів прямо до вашого дверей, щоб ви могли насолодитися своїм святом без зайвих зусиль.</p>
            </div>
            <div className='flex flex-col gap-[8px]'>
              <h6>Професійна консультація</h6>
              <p>Наші експерти завжди готові допомогти вам з вибором товарів та відповісти на всі ваші запитання, щоб зробити ваше свято найяскравішим та найбільш незабутнім.</p>
            </div>
            <div className='flex flex-col gap-[8px]'>
              <h6>Особисте обслуговування:</h6>
              <p>Ми цінуємо кожного клієнта і намагаємося забезпечити індивідуальний підхід до кожного замовлення, щоб ви були повністю задоволені нашими послугами.</p>
            </div>
            <div>
              <a href='/shop' className='button buzok'>Перейти в Каталог</a>
            </div>
          </div>
        </div>
      </div>
    </section>
  </>
  )
}

export default About