
import './SocialIcon.css'
import IconPhohe from '../../../Assets/Icons/Social/phone.svg'
import IconInstagram from '../../../Assets/Icons/Social/instagram.svg'
import IconTelegram from '../../../Assets/Icons/Social/telegram.svg'
import IconViber from '../../../Assets/Icons/Social/viber.svg'
import IconMail from '../../../Assets/Icons/Social/mail.svg'

function SocialIcon() {

  return (
  <div>
    <p>Зв’язатися на пряму:</p>
    <div className='flex gap-[20px] mt-[10px]'>
        <a href='tel:+380631531173'><img src={IconPhohe} alt='' /></a>
        {/* <a href='https://www.instagram.com/fotozona_kiev_mj_decor_agency?igsh=MTJ0ZWUyc2JzdXVqZA=='><img src={IconInstagram} alt='' /></a> */}
        <a href='https://t.me/+380631531173'><img src={IconTelegram} alt='' /></a>
        <a href='viber://chat?number=+380631531173'><img src={IconViber} alt='' /></a>
        <a href='mailto:ballonflower2024@gmail.com'><img src={IconMail} alt='' /></a>
    </div>
  </div>
  )
}

export default SocialIcon